<template>
  <div class="container px-4 mb-5 pb-5 z-depth-1 about-call">
    <!--Section: Content-->
    <section class="px-md-5 mx-md-5 text-center text-lg-left">
      <h3 class="font-weight-bold fall-down">
        Investissez dans l'avenir de votre entreprise.
      </h3>

      <p class="mb-5 text-center fall-down">
        En choisissant you<span class="color-primary">can</span>learn, vous
        faites bien plus que simplement <strong>former vos équipes</strong>.
        Vous investissez dans l'avenir et la croissance de votre entreprise.
      </p>

      <!--Grid row-->
      <div class="row my-5 py-5">
        <!--Grid column-->
        <div class="col-12 col-lg-6 mb-lg-0 mb-4 slide-left">
          <img
            src="@/assets/pictures/jc-mockup.webp"
            class="img-fluid"
            alt="mockup-mobile-of-the-app"
          />
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-12 col-lg-6 mb-lg-0 mb-lg-0 mb-2 slide-right">
          <h4 class="my-4">Plus de renseignements ?</h4>

          <p class="mb-5">
            Voulez-vous faire un essai ? Découvrez comment transformer votre
            entreprise en une force de travail agile et compétente, Vous
            permettant également de réaliser des économies sur le budget destiné
            à la <strong>formation dans votre entreprise</strong>.
          </p>
          <div class="text-center text-lg-start">
            <a class="btn btn-md waves-effect mr-2" href="/about" role="button"
              >Services</a
            >
            <a class="btn btn-md" href="/contact" role="button"
              >Contact <i class="fa fa-magic ml-1"></i
            ></a>
          </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <!--Grid column-->
      </div>
      <!--Grid row-->
    </section>
    <!--Section: Content-->
  </div>
</template>

<script>
export default {
  name: "AboutCallAction",
};
</script>

<style lang="scss" scoped>
.color-primary {
  color: $color-primary;
}
.about-call {
  h3,
  p {
    color: $color-lighter;
  }
  & h4 {
    color: $color-primary;
  }
  & a {
    color: $color-text;
    border: 0.5px inset $color-text;
    border-radius: 5px;
  }
  & a:nth-child(1) {
    background-color: $color-primary;
    color: $color-darker;
    margin-right: 10px;
    &:hover {
      background-color: $color-darker;
      color: $color-primary;
    }
  }
  & a:nth-child(2) {
    background-color: $color-darker;
    &:hover {
      background-color: $color-darker;
      color: $color-primary;
    }
  }
}
</style>
