<template>
  <header class="contact-header p-4">
    <h1 class="fall-down">CONTACT</h1>
    <p class="image-fade-in">
      Une question sur nos <strong>formations pour entreprise ?</strong> Une
      <strong>Démo en live</strong><span class="fake-space">?</span
      ><br />N'hésitez pas on répond à toutes vos questions.
    </p>
  </header>
</template>

<script>
export default {
  name: "ContactHeader",
};
</script>

<style lang="scss" scoped>
.contact-header {
  height: 50vh;
  /*background-image: url("@/assets/pictures/brain-particule.webp");
  background-size: contain;
  @media screen and (min-width: 992px) {
    background-size: cover;
  }
  background-repeat: no-repeat;
  background-position: center center;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & h1 {
    color: $color-primary;
    font-size: 3em;
    text-align: center;
  }
  & p {
    color: $color-text;
    font-size: 1.3em;
    text-align: center;
    & .fake-space {
      margin-left: 8px;
    }
  }
}
</style>
