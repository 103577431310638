<template>
  <section class="nature-section" ref="iaSection">
    <h2 class="section-title mb-5">
      L’IA – intelligence artificielle au service de vos équipes
    </h2>
    <div class="nature-content mt-5">
      <div class="img">
        <video
          ref="iaVideo"
          src="@/assets/pictures/video-ia.mp4"
          muted
          loop
          playsinline
          style="
            max-width: 100%;
            height: auto;
            box-shadow: 0 0.2em 1.2em hsla(20, 20, 20, 10%);
          "
        ></video>
        <p class="img-credit">Powered by IA</p>
      </div>
      <div class="content">
        <p>
          Notre technologie mix la création de contenu vidéo de type
          <strong>Micro Learning</strong> et de l’intelligence artificielle pour
          créer les formations dont vous avez besoin.<br />L’objectif principal
          de YCLA est de transmettre de manière très rapide et sans frictions le
          maximum de connaissances et de compétences à vos collaborateurs, sans
          avoir à dédier un bloc de plusieurs jours mais avec un mode de
          répétition dans le temps qui permet un meilleur ancrage.
        </p>
        <a href="/about" class="learn-more">découvrir</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  mounted() {
    const videoElement = this.$refs.iaVideo;
    const sectionElement = this.$refs.iaSection;

    // Intersection Observer pour démarrer/arrêter la vidéo selon la visibilité de la section
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.play(); // Démarre la vidéo si la section est visible
          } else {
            videoElement.pause(); // Met la vidéo en pause si la section n'est plus visible
          }
        });
      },
      {
        threshold: 0.5, // 50% de la section doit être visible pour déclencher la lecture
      }
    );

    observer.observe(sectionElement);
  },
};
</script>

<style lang="scss" scoped>
/*:root {
  --box-padding: 2rem;
}*/

.nature-section {
  max-width: 1200px;
  margin: 100px auto;
  padding: var(--box-padding);
  @media (min-width: 992px) {
    background-image: url("@/assets/pictures/ia-binary.webp");
    background-size: contain;
    background-position: 200%;
    background-repeat: no-repeat;
  }
}

.section-title {
  text-align: center;
  margin: 10px 10px 30px;
  color: $color-lighter;
  @media (min-width: 992px) {
    font-size: 4rem;
  }
}

.nature-content {
  display: flex;
  flex-direction: column;
}

.img {
  margin-bottom: 1.5rem;
  text-align: center;
}

.img video {
  max-width: 100%;
  height: auto;
  box-shadow: 0 0.2em 1.2em hsla(20, 20, 20, 10%);
}

.img-credit {
  display: inline-block;
  margin-top: 0.5rem;
  background-color: black;
  color: white;
  padding: 4px 6px;
  border-radius: 3px;
  font-size: 12px;
}

.content {
  color: $color-text;
  text-align: left;
  margin: 0px 10px;
  & p {
    font-size: 20px;
    @media (min-width: 992px) {
      background-color: rgba(0, 0, 0, 0.4);
      padding: 10px;
      border-radius: 12px;
    }
  }
}

.content a {
  text-decoration: none;
  border: 1px solid hsl(56, 95%, 50%);
  border-radius: 1em;
  padding: 1em 2em;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  letter-spacing: 0.05em;
  color: inherit;
  background-color: $color-darker;
  display: inline-block;
  margin-top: 1rem;
}

/* Responsive: Switch to row layout on larger screens */
@media (min-width: 768px) {
  .nature-content {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .img {
    flex: 1;
    margin-right: 2rem;
    margin-bottom: 0;
    text-align: left;
  }

  .content {
    flex: 1;
    padding-left: 2rem;
  }
}
</style>
